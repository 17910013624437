<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<span class="subtitle-1">Генерация QR для домофона</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container>
					<v-form ref="form">
						<v-row>
							<v-col cols="12" md="6">
								<span class="subtitle-2 font-weight-light">
									Адрес
								</span>
								<v-autocomplete
									v-model="data.house"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									:filter="addressFilter"
									:items="housesWithoutQrPorch"
									item-text="apartment_address"
									return-object
									required
									@change="findHouse"
								></v-autocomplete>
							</v-col>
							<!-- Номер подъезда -->
							<v-col cols="12" md="6">
								<span class="subtitle-2 font-weight-light">
									Номер подъезда
								</span>
								<v-text-field
									v-model="data.porch"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- РКА -->
							<v-col cols="12" md="6">
								<span class="subtitle-2 font-weight-light">
									РКА
								</span>
								<v-text-field
									v-model="data.rca"
									v-mask="'################'"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
									:disabled="foundRca"
								></v-text-field>
							</v-col>
							<!-- Пароль Wifi сети -->
							<v-col cols="12" md="6">
								<span class="subtitle-2 font-weight-light">
									Пароль Wifi сети
								</span>
								<v-text-field
									v-model="data.wifi_password"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- Показать QR, если он сгенерирован -->
							<v-col md="12" v-if="qr">
								<v-img :src="qr.image" width="300px"></v-img>
								<v-btn
									class="subtitle-2"
									color="primary"
									outlined
									target="_blank"
									:href="qr.image"
									:download="qr.name"
								>
									Загрузить QR
								</v-btn>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<v-card-actions class="pt-0 pb-5 px-8 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isAdding"
					@click="generateQr"
				>
					Сгенерировать
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "QrTablePorchModal",
	data() {
		return {
			alert: null,
			data: {
				house: null,
				porch: "",
				wifi_password: "",
				rca: "",
			},
			qr: null,
			dialog: true,
			loading: true,
			isAdding: false,
			foundRca: false,
		};
	},
	computed: {
		...mapGetters({
			role: "auth/GET_USER_ROLE_VUEX",
			housesWithoutQrPorch: "qr/GET_HOUSES_WITHOUT_QR_PORCH",
		}),
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 5000);
		},
	},
	methods: {
		async findHouse() {
			this.foundRca = false;
			try {
				const res = await this.$api.ksk.load_house_by_id(this.data.house._id);
				if (res?.rca) {
					this.data.rca = res.rca;
					this.foundRca = true;
					return;
				}
				this.data.rca = "";
			} catch (error) {
				this.alert = {
					color: "error",
					message: "Произошла ошибка",
				};
				console.error(error);
			}
		},
		// Валидация
		customValidation() {
			let message;

			if (!this.data.phone) {
				message = "Укажите номер телефона";
			} else if (!this.data.car_number) {
				message = "Укажите гос. номер";
			} else if (!this.data.house_id) {
				message = "Укажите адрес";
			} else if (!this.data.parking_place) {
				message = "Укажите паркоместо";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}

			return true;
		},
		// Генерирование QR
		async generateQr() {
			this.alert = null;

			// if (!this.customValidation()) return;

			this.isAdding = true;

			try {
				let res;

				// Установка Wifi настроек
				const wifiSettings = {
					rca: this.data.rca,
					wifi_password: this.data.wifi_password,
				};

				await this.$api[this.role].set_wifi_password(
					this.data.house._id,
					wifiSettings
				);

				// Генерация QR
				const params = {
					house_id: this.data.house._id,
					porch: this.data.porch,
				};

				res = await this.$api[this.role].generate_qr_porch(params);
				this.qr = {};
				this.qr.image = res;
				this.qr.name = `${this.data.house.apartment_address}.png`;

				this.alert = {
					color: "success",
					message: res.success || "QR сгенерирован",
				};

				this.$refs.form.resetValidation();
				this.$emit("update-info");
			} catch (error) {
				console.log(error);
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAdding = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async created() {
		try {
			this.loading = true;
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
