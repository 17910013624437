<template>
	<div class="qr-table-barrier">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">QR</span>
							</v-btn>
							<!-- Период -->
							<div class="d-flex mr-5">
								<v-select
									v-model="filter.period"
									class="border-all"
									:menu-props="{ offsetY: true }"
									solo
									dense
									flat
									hide-details
									:items="[
										{ text: 'За все время', value: '' },
										{ text: 'День', value: 'day' },
										{ text: 'Неделя', value: 'week' },
										{ text: 'Месяц', value: 'month' },
										{ text: 'Задать период', value: 'period' },
									]"
									@change="updateQuery"
								></v-select>
							</div>
							<!-- Дата создания -->
							<div
								class="d-flex align-center mr-3"
								v-if="filter.period === 'period'"
							>
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.date_first"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.date_second"
									@change="updateQuery"
								/>
								<v-btn class="ml-3" icon>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- <v-btn
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Статус -->
					<td>
						<v-select
							v-model="filter.entered"
							class="border-all"
							:menu-props="{ offsetY: true }"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Успешные', value: '1' },
								{ text: 'Неуспешные', value: '0' },
							]"
							@change="updateQuery"
						></v-select>
					</td>
					<!-- ФИО -->
					<td>
						<v-text-field
							v-model="filter.fio"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Телефон -->
					<td>
						<v-text-field
							v-model="filter.phone"
							class="border-all"
							:placeholder="phoneCode+'-'"
							v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Адрес -->
					<td>
						<v-autocomplete
							v-model="filter.apartment_address"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							:filter="addressFilter"
							:items="housesWithQrPorch"
							item-text="apartment_address"
							item-value="apartment_address"
							@change="updateQuery"
						>
						</v-autocomplete>
					</td>
					<!-- Подъезд -->
					<td>
						<v-text-field
							v-model="filter.porch"
							class="border-all"
							solo
							dense
							flat
							clearable
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Номер въезда -->
					<td>
						<v-text-field
							v-model="filter.apartment_number"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Дата и время -->
					<td>
						<DatePickerPlain
							:date.sync="filter.datetime"
							:in-table="true"
							nudge-left="115px"
							@change="updateQuery"
						/>
					</td>
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.fio`]="{ item }">
				<td>
					{{ item.user | fullname }}
				</td>
			</template>
			<template v-slot:[`item.phone`]="{ item }">
				<td>
					{{ item.user ? item.user.phone : "---" }}
				</td>
			</template>
			<template v-slot:[`item.apartment_address`]="{ item }">
				<td>
					{{ item.house ? item.house.apartment_address : "---" }}
				</td>
			</template>
			<template v-slot:[`item.entered`]="{ item }">
				<td>
					<v-icon v-if="item.entered" color="primary">
						mdi-check-circle
					</v-icon>
					<v-icon v-else color="red">mdi-close-circle</v-icon>
				</td>
			</template>
			<template v-slot:[`item.datetime`]="{ item }">
				<td>
					{{ item.datetime | timestamp(true, { utc: true }) }}
				</td>
			</template>
			<template v-slot:[`item.apartment_number`]="{ item }">
				<td>
					{{ item.apartment ? item.apartment.apartment_number : "---" }}
				</td>
			</template>
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ QR -->
		<QrTablePorchModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			@close-modal="showModalCreate = false"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import QrTablePorchModal from "@/components/QrTablePorchModal";

export default {
	name: "QrTablePorch",
	components: {
		DatePickerPlain,
		QrTablePorchModal,
	},
	watch: {
		options: {
			handler() {
				const query = { ...this.$route.query, page: this.options.page };
				this.$router.replace({ query });
				this.loadData(query);
			},
			deep: true,
		},
	},
	data() {
		return {
			items: [],
			filter: {
				apartment_address: this.$route.query.apartment_address || "",
				apartment_number: this.$route.query.apartment_number || "",
				datetime: this.$route.query.datetime || "",
				date_first: this.$route.query.date_first || "",
				date_second: this.$route.query.date_second || "",
				entered: this.$route.query.entered || "",
				fio: this.$route.query.fio || "",
				page: this.$route.query.page || "",
				period: this.$route.query.period || "",
				phone: this.$route.query.phone || "",
				porch: this.$route.query.porch || "",
			},
			filtersQuery: {
				"apartment_address": "address",
				"apartment_number": "apartment_number",
				"datetime": "datetime",
				"date_first": "date_first",
				"date_second": "date_second",
				"entered": "entered",
				"fio": "fio",
				"page": "page",
				"period": "period",
				"phone": "phone",
				"porch": "porch",
			},
			headers: [
				{ text: "Статус", value: "entered", width: "150px", sortable: false },
				{ text: "ФИО", value: "fio", width: "250px", sortable: false },
				{ text: "Телефон", value: "phone", width: "180px", sortable: false },
				{
					text: "Адрес",
					value: "apartment_address",
					width: "300px",
					sortable: false,
				},
				{ text: "Подъезд", value: "porch", width: "150px", sortable: false },
				{
					text: "Квартира",
					value: "apartment_number",
					width: "150px",
					sortable: false,
				},
				{
					text: "Дата и время",
					value: "datetime",
					width: "200px",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: true,
			showModalCreate: false,
		};
	},
	computed: {
		...mapGetters({
			role: "auth/GET_USER_ROLE_VUEX",
			catalog: "catalog/GET_CATALOG",
			housesWithQrPorch: `qr/GET_HOUSES_WITH_QR_PORCH`,
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
	},
	methods: {
		timestampToDdmmyyyy,

		getAddress(item) {
			return item?.apartment?.house?.apartment_address || "---";
		},

		// ? Методы таблицы
		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.options.page = 1
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		loadData(params) {
			this.loading = true;
			return this.$api[this.role]
				.load_logs_porch(params)
				.then(res => {
					// this.options.page = res.page;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});
			this.options.page = 1
			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch(`qr/load_houses_qr_porch`);
	},

	async mounted() {
		try {
			const query = { ...this.$route.query };
			await this.loadData(query);
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped>
.qr-table-barrier {
	height: 100vh;
}
</style>
